import React, { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import PhotoGalleryPopUp from './PhotoGalleryPopUp';
import PhotoGalleryData from '../../Data/PhotoGalleryData';

function PhotoGallery() {

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePopupOpen = (index) => {
    console.log("index:-",index)
    setIsPopupOpen(true);
    setCurrentIndex(index)
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
    <Header/>
    <div className="relative">
      <img src={'/images/photo_gallery_banner.svg'} alt="Photos Gallery" className="w-full object-cover" />
      <div className="absolute top-0 mt-24 left-0">
        {/* <div className="text-white text-center">
          <h1 className="photoGallery">Photo Gallery</h1>
        </div> */}
      </div>
    </div>

    <section class="text-gray-600 body-font mt-16">
    <div class="container px-4 mx-auto">
    <h1 className="text-3xl title-font font-medium mb-4 photoGallaryHeader photoGallaryHeaderMobile lg:ml-10 ">
      Accommodations Photos      
      </h1>
    <div class="flex flex-wrap -m-4 lg:mx-5 mt-14">
     {
      PhotoGalleryData && PhotoGalleryData.map((data,index)=>{
        console.log("data:-",data,data.img)
        return (
          <div class="md:p-4 lg:w-1/3 md:w-1/2 " onClick={() => handlePopupOpen(index)} key={index}>
    <div class="h-full flex flex-col items-center text-left photoGalleryMobile">
          <img alt="team" class="flex-shrink-0  w-full h-100 object-cover object-center"  src= {data.img} />  
        </div>
    </div>
        )
      })
    } 

    </div>
    </div>
  </section>

  <PhotoGalleryPopUp isOpen={isPopupOpen} onClose={handlePopupClose} currentIndex={currentIndex}/>
    <Footer/>
    </>

  );
}

export default PhotoGallery;

