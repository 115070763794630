import React, { useState, useEffect } from 'react';
import FooterInformation from '../Containers/FooterInformation';
import FooterMessage from '../Containers/FooterMessage';
import FooterContact from '../Containers/FooterContact';
import FooterMenu from '../Containers/FooterMenu';
import FooterCopyrights from '../Containers/FooterCopyrights';
import { Link } from 'react-router-dom';
import ContactUsPopup from './Packages/Contact';

function Footer() {
    const [activeMenu2, setActiveMenu] = useState('Home'); 
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handlePopupOpen = () => {
      setIsPopupOpen(true);
    };
  
    const handlePopupClose = () => {
      setIsPopupOpen(false);
    };
    useEffect(() => {
      const savedMenu = sessionStorage.getItem('activeMenu2');
      if (savedMenu) {
        setActiveMenu(savedMenu);
      } else {
        setActiveMenu('Home'); 
        sessionStorage.setItem('activeMenu2', 'Home'); 
      }
    }, []);
  
    const handleMenuClick = (menu) => {
      setActiveMenu(menu);
      sessionStorage.setItem('activeMenu2', menu); 
    };

    return (
        <>
        <hr className='mt-16 hr1 footerMarginMobile'></hr>
        <hr className='hr2'></hr>
        <footer className="bg-white p-8">
            <div className="container mx-auto grid grid-cols-12 gap-4">
            {/* first div */}
            <div className="col-span-12 md:col-span-4 md:col-start-1 lg:ml-20">
                <FooterInformation />
            </div>


            <div className="col-span-12 md:col-span-2 md:col-start-6 lg:mt-5 md:mt-5">
            <FooterContact />
            </div>

            {/* Footer Part */}
            <div className="col-span-12 md:col-span-2 md:col-start-10 lg:mt-20 md:mt-5 footerIconMobile">
            {/* <FooterContact /> */}
            <h2 className="text-xl font-bold mt-4  mb-4">Quick Links</h2>

            <footer class="text-gray-600 body-font">
                <div class="container mx-auto  mt-5 flex-col md:flex-row items-center">

                <nav class=" md:ml-auto md:mr-auto text-base justify-center">
                <ul>
                    <li className='mt-5'>
                    <Link
                        to="/"
                        className={`mr-8 FooterMenu ${
                        activeMenu2 === 'Home' ? 'menu-active1' : ''
                    }`}
                    onClick={() => handleMenuClick('Home')}
                    >
                    Home
                    </Link>                    
                    </li>

                    <li className='mt-2'>
                    <Link
                        to="/TourPackages"
                        className={`mr-8 FooterMenu ${
                        activeMenu2 === 'Tour' ? 'menu-active1' : ''
                    }`}
                    onClick={() => handleMenuClick('Tour')}
                    >
                    Tour Packages
                    </Link>                    
                    </li>

                    <li className='mt-2'>
                    <Link
                        to="/PhotoGallery"
                        className={`mr-8 FooterMenu ${
                        activeMenu2 === 'Gallery' ? 'menu-active1' : ''
                    }`}
                    onClick={() => handleMenuClick('Gallery')}
                    >
                    Photo Gallery
                    </Link>                    
                    </li>

                    <li className='mt-2'>
                    <Link to=""
                        className={`mr-8 FooterMenu ${
                        activeMenu2 === 'Contact' ? 'menu-active1' : ''
                    }`}
                    onClick={() =>
                    {
                        handleMenuClick('Contact');
                        handlePopupOpen();
                    }
                     }
                    >
                    Contact Us
                    </Link>                    
                    </li>
                </ul>
                </nav>
                </div>
            </footer>
            </div>
        </div>
        </footer>
        {/* <FooterMenu/> */}
        <hr className='hr3 copyFooterMargin'></hr>

        <FooterCopyrights/>
        <ContactUsPopup isOpen={isPopupOpen} onClose={handlePopupClose} />

        </>     
      );
}

export default Footer;
