import React from 'react';

function Packages() {
  return (
    <>

    {/* for large screen............................................. */}
      <div className="bg-cover bg-center min-h-screen p-6  mt-20 my-10 packageCardNone"
style={{ backgroundImage: `url(${"/images/PackageImage.svg"})`}}>
<div className='mx-3'>
<h2 class="text-sm title-font text-gray-500 tracking-widest welcomeFont uppercase md:mt-10 lg:ml-16 packageWelcomeFontMobile">packages</h2>

<div className="grid grid-cols-12 gap-4">
  <div className="col-span-12 md:col-span-6  bg-opacity-80  rounded-lg">
    <h2 className="text-2xl font-bold mb-4 packageHeader packageHeaderMobile lg:ml-16 mb-10">Experience the <span className='welcomeHeaderPink'>comfort and security</span> of home with our supportive senior care package</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mx-4 lg:mx-16 mb-4 ">
  <div className="PackageData text-center">
    <img src={"/images/Grandfather.svg"} alt="Single Person Package" className="mx-auto mb-2 mt-10"/>
    <p className='PackageCardText mb-6 text-base sm:text-lg lg:text-xl'>Single Person<br/>Package</p>
  </div>
  <div className="PackageData text-center">
    <img src={"/images/Couple.svg"} alt="Couple Package" className="mx-auto mb-2 mt-10"/>
    <p className='PackageCardText mb-6 text-base sm:text-lg lg:text-xl'>Couple package</p>
  </div>
  <div className="PackageData text-center">
    <img src={"/images/Temple.svg"} alt="Package with Pilgrimage" className="mx-auto mb-2 mt-10"/>
    <p className='PackageCardText mb-6 text-base sm:text-lg lg:text-xl'>Package with<br/>pilgrimage</p>
  </div>
</div>

  <p className='text-right mr-24 packageFooter'>Packages are available for 1, 3, 6 months</p>
  </div>
  <div className="col-span-12 md:col-start-8 md:col-span-5  bg-opacity-80 p-6 rounded-lg mr-10">
    <p className='mt-1 PackageContentFirst'>More than just booking vacations, we curate transformative journeys for our senior citizens, ensuring seamless medical support, Ayurvedic revitalization, and spiritual reawakening amidst India’s vibrant splendor.</p>
    <p className='mt-5 PackageContentSecond'>Imagine relaxing in your spacious 2-bedroom apartment, enjoying delicious meals prepared by a cook, and receiving personalized care from dedicated professionals. We help you stay active and engaged with physiotherapy sessions and fun sightseeing trips, creating a truly fulfilling experience.</p>
        <div className='mt-5'>
        <img src={"/images/gujrat.svg"} alt="Single Person Package" />
        </div>
  </div>
</div>
</div>
      </div>


    {/* for mobile screen............................................. */}
    <div className="bg-cover bg-center min-h-screen p-6  mt-20 my-10  md:hidden lg:hidden packageBorderRediusMobile"
style={{ backgroundImage: `url(${"/images/PackageImage.svg"})`,backgroundSize:'contain',backgroundRepeat:"no-repeat",backgroundPosition:'center bottom'}}>
<div className='mx-3'>
<h2 class="text-sm title-font text-gray-500 tracking-widest welcomeFont uppercase md:mt-10 lg:ml-16 packageWelcomeFontMobile">packages</h2>


<div className="grid grid-cols-12 gap-4">
  <div className="col-span-12 md:col-span-6  bg-opacity-80  rounded-lg">
    <h2 className="text-2xl font-bold mb-4 packageHeader packageHeaderMobile lg:ml-16 mb-10">Experience the <span className='welcomeHeaderPink'>comfort and security</span> of home with our supportive senior care package</h2>
    <div className="col-span-12 md:col-start-8 md:col-span-5  bg-opacity-80 rounded-lg ">
    <p className='mt-1 packageContentFirstMobile'>More than just booking vacations, we curate transformative journeys for our senior citizens, ensuring seamless medical support, Ayurvedic revitalization, and spiritual reawakening amidst India’s vibrant splendor.</p>
    <p className='mt-5 packageContentSecondMobile'>Imagine relaxing in your spacious 2-bedroom apartment, enjoying delicious meals prepared by a cook, and receiving personalized care from dedicated professionals. We help you stay active and engaged with physiotherapy sessions and fun sightseeing trips, creating a truly fulfilling experience.</p>
    <p className='mt-2 packageContentThirdMobile'>Packages are available for 1, 3, 6 months</p>

    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4  lg:mx-16 mb-4 ">
  <div className="PackageDataMobile text-center">
    <img src={"/images/Grandfather.svg"} alt="Single Person Package" className="mx-auto mb-2 mt-5"/>
    <p className='PackageCardTextMobile  mb-6 text-base sm:text-lg lg:text-xl'>Single Person<br/>Package</p>
  </div>
  <div className="PackageDataMobile text-center">
    <img src={"/images/Couple.svg"} alt="Couple Package" className="mx-auto mb-2 mt-5"/>
    <p className='PackageCardTextMobile mb-6 text-base sm:text-lg lg:text-xl'>Couple package</p>
  </div>
  <div className="PackageDataMobile text-center">
    <img src={"/images/Temple.svg"} alt="Package with Pilgrimage" className="mx-auto mb-2 mt-5"/>
    <p className='PackageCardTextMobile mb-6 text-base sm:text-lg lg:text-xl'>Package with<br/>pilgrimage</p>
  </div>
</div>
        <div className='mt-5'>
        <img src={"/images/gujrat.svg"} alt="Single Person Package" />
        </div>
  </div>
    


  </div>

</div>
</div>
    </div>
    </>



  );
}

export default Packages;
