import React from 'react'; 
import BannerData from '../Data/BannerData';
import { Link } from 'react-router-dom';

function Banner() {
    return (
        <div className="relative bg-cover bg-center h-screen bannerPic bannerPicTablate" style={{ backgroundImage: BannerData.bannerImage}}>
          <div className="absolute inset-0 opacity-50"></div>
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-center text-white px-4">
            <h1 className="text-3xl md:text-5xl font-bold lg:mb-4  md:mb-4 bannerHeaderFont">{BannerData.mainContant}</h1>
            <p className="text-xl md:text-2xl lg:mb-8  bannerHeaderContent">{BannerData.secondContant}</p>
            <Link to="/TourPackages">
            <button className="bg-pink-500 navbar-button hover:bg-pink-600 text-white font-semibold py-2 px-4 rounded bannerButton">
              {BannerData.buttonText}
            </button>
            </Link>

          </div>
        </div>
      );
}

export default Banner;