let Data=[
{
    img:"/images/InfoCard1.svg",
    headerData:"Strolling down memory lane",
    mainText:"Explore the vibrant streets of Ahmedabad, reliving cherished memories and rediscovering old haunts."
},
{
    img:"/images/InfoCard2.svg",
    headerData:"Delighting in delicious, healthy meals",
    mainText:"Savor homemade dishes prepared with love, catering to individual dietary needs"
},
{
    img:"/images/InfoCard3.svg",
    headerData:"Indulging in rejuvenating Ayurvedic therapies",
    mainText:"Traditional remedies revitalize and restore, fostering holistic well-being"
},
{
    img:"/images/InfoCard4.svg",
    headerData:"Skip the waiting room, embrace comfort",
    mainText:"Home Physiotherapy sessions and Medical Appointment Assistance"
},
]

export default Data;