import './App.css';
import Home from './Component/Home';
import SinglePerson from './Component/Packages/SinglePerson';
import {BrowserRouter,Routes,Route} from 'react-router-dom';
import TourPackages from './Component/TourPackages';
import CouplePackage from './Component/Packages/CouplePackage';
import ThreeMonthPackage from './Component/Packages/ThreeMonthPackage';
import PhotoGallery from './Component/Packages/PhotoGallery';

function App() {
  return (
    <> 
      <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/TourPackages' element={<TourPackages/>}/>
        <Route path='/SinglePerson' element={<SinglePerson/>}/>
        <Route path='/CouplePackage' element={<CouplePackage/>}/>
        <Route path='/ThreeMonthPackge' element={<ThreeMonthPackage/>}/>    
        <Route path='/PhotoGallery' element={<PhotoGallery/>}/>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
