import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link, json, useNavigate,useParams } from 'react-router-dom';

function TourPackages() {

  // const navigate=useNavigate()

  // const LoadEdit=()=>{
  //   navigate('/SinglePerson');
  // }

  return (
    <>
    <Header/>
    {/* Main banner for  */}

    <div className="relative">
      <img src={'/images/tour_package.svg'} alt="Single Person Package" className="w-full object-cover tourPackageTablate"/>
    </div>

    {/* Cards for tour packages */}

    <section className="text-gray-600 body-font overflow-hidden lg:mt-20 mt-10 md:mt-20">
      <div className="container md:y-24 mx-auto lg:ml-28 md:ml-10 sm:ml-5">
      <h2 className="text-sm title-font text-gray-500 tracking-widest welcomeFont uppercase  offeringFontMobile">Offerings</h2>
      <h1 className="text-3xl title-font font-medium mb-4 offeringHeader  offeringHeaderMobile mb-10">
      Tour Packages        
      </h1>
      </div>
    </section>

    <section class="text-gray-600 body-font">
    <div class="container px-12 mx-auto">
    <div class="flex flex-wrap -m-4 lg:mx-5">

    {/* div 1   */}
    <div class="md:p-4 lg:w-1/3 md:w-1/2 TourpackagesMarginMobile" >
    <Link to='/SinglePerson'>
    <div class="h-full flex flex-col items-center text-left singlePageBg1">
          <img alt="team" class="flex-shrink-0  w-full h-100 object-cover object-center mb-4" src="/images/tour_package_img1.svg"/>  
          <div class="w-full mb-5">
            <h2 class="title-font font-medium text-lg text-gray-900 singlePagetext">Single Person Package</h2>
          </div>
        </div>
    </Link>
    </div>
 
        {/* div 2   */}
    <div class="md:p-4 lg:w-1/3 md:w-1/2 TourpackagesMarginMobile">
    <Link to='/CouplePackage'>
        <div class="h-full flex flex-col items-center text-left singlePageBg2 ">
          <img alt="team" class="flex-shrink-0  w-full h-100 object-cover object-center mb-4" src="/images/tour_package_img2.svg"/>  
          <div class="w-full mb-5">
            <h2 class="title-font font-medium text-lg text-gray-900  singlePagetext">Couple Package</h2>
          </div>
        </div>
        </Link>
    </div>

      {/* div 3   */}
      <div class="md:p-4 lg:w-1/3 md:w-1/2 TourpackagesMarginMobile">
      <Link to='/ThreeMonthPackge'>
        <div class="h-full flex flex-col items-center text-left singlePageBg3">
          <img alt="team" class="flex-shrink-0  w-full h-100 object-cover object-center mb-4" src="/images/tour_package_img3.svg"/>  
          <div class="w-full mb-5">
            <h2 class="title-font font-medium text-lg text-gray-900 singlePagetext">Three Months Package</h2>
          </div>
        </div>
        </Link>
      </div>
    </div>
    </div>
  </section>
    <Footer/>
    </>
   
  );
}

export default TourPackages;
