import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactUsPopup from './Packages/Contact'

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home'); 

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const savedMenu = sessionStorage.getItem('activeMenu');
    if (savedMenu) {
      setActiveMenu(savedMenu);
    } else {
      setActiveMenu('Home'); 
      sessionStorage.setItem('activeMenu', 'Home'); 
    }
  }, []);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    sessionStorage.setItem('activeMenu', menu); 
  };

  return (
    <nav className="flex items-center justify-between flex-wrap p-6 lg:ml-28">
      <div className="flex items-center flex-shrink-0 text-white mr-6 lg:mr-8">
        <img src="/images/atithilogo.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </div>
      <div className="block lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex px-3 py-2 rounded text-black-500 hover:text-black-400"
        >
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>
      <div
        className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}
      >
        <div className="text-sm lg:flex-grow items-center lg:ml-20">
          <Link
            to="/"
            className={`menu-color menu-hover block mt-4 lg:inline-block lg:mt-0 mr-12 ${
              activeMenu === 'Home' ? 'menu-active' : ''
            }`}
            onClick={() => handleMenuClick('Home')}
          >
            Home
          </Link>

      
          <Link to='/TourPackages' className={`menu-color menu-hover block mt-4 lg:inline-block lg:mt-0 mr-12 ${
              activeMenu === 'Packages' ? 'menu-active' : ''
            }`}
            onClick={() => handleMenuClick('Packages')}>
            Tour Packages
            </Link>

            <Link to='/PhotoGallery' className={`menu-color menu-hover block mt-4 lg:inline-block lg:mt-0 mr-12 ${
              activeMenu === 'Gallery' ? 'menu-active' : ''
            }`}
            onClick={() => handleMenuClick('Gallery')}>
            Photo Gallery
            </Link>

            <div
            
            className={`menu-color menu-hover block mt-4 lg:inline-block lg:mt-0 mr-12 ${
              activeMenu === 'Contact' ? 'menu-active' : ''
            }`}
            onClick={() =>{
              handleMenuClick('Contact');
              handlePopupOpen();
            }}
            >
            Contact
            </div>

        </div>
      </div>
      <ContactUsPopup isOpen={isPopupOpen} onClose={handlePopupClose} />

    </nav>

  );
}

export default Header;












