import React, { useState} from 'react';

function CoupleContact({ isOpen, onClose }) {
  
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    age: '',
    gender: '',
    spouseFirstName:'',
    spouseLastName:'',
    spouseAge:'',
    spouseGender:'',
    phone: '',
    email: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    message: ''
  });
  
  if (!isOpen) return null;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch('https://us-central1-atithi-631e5.cloudfunctions.net/couplePackageContactUsEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      return response.text().then(text => {
        try {
          return JSON.parse(text);
        } catch {
          throw new Error(text);
        }
      });
    })
    .then(data => {
      console.log('Success:', data);
      alert('Message sent successfully!');
    })
    
    .catch((error) => {
      console.error('Error:', error);
      // alert('Error sending message: ' + error.message);
      alert("Thanks for your inquiry. Atithibhv team will contact you soon.");
      console.error('Error sending message: ' + error.message);
      window.location.reload();
    });
    // window.location.reload();
  };

    return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
      {/* <div className="bg-white p-8 rounded-lg shadow-lg relative w-full max-w-lg singleContactForm overflow-y-auto"> */}
      <div className="bg-white rounded-lg shadow-lg relative w-full max-w-lg max-h-screen singleContactForm overflow-y-auto md:px-20 mobileForm">
  
        <button onClick={onClose} className="absolute top-2 right-4 text-gray-600 mr-5">
          &times;
        </button>
        <h2 className="text-2xl font-bold mb-4 HeaderForm mt-10 mb-8 mobileFormHeader">Let's connect!</h2>
        <form className="grid gap-4 sm:grid-cols-2" onSubmit={handleSubmit}>
  
          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
            <input type="text" className="w-full border border-gray-300 p-2 rounded inputDouble" 
              name="firstName"
              onChange={handleChange} value={formData.firstName} required/>
            <span class="placeholder">Your First Name</span>
          </div>

          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
            <input type="text"  className="w-full border border-gray-300 p-2 rounded inputDouble" 
              name="lastName"
              value={formData.lastName}
              onChange={handleChange} required/>
            <span class="placeholder">Your Last Name</span>
          </div>

          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
          <input type="text" className="w-full border border-gray-300 p-2 rounded inputDouble" 
             name="age"
              value={formData.age}
              onChange={handleChange} required/>
          <span class="placeholder">Age</span>
        </div>
  
        <div className="col-span-2 sm:col-span-1 mb-1">
          <select className="w-full border border-gray-300 p-2 rounded inputDouble" 
           name="gender"
              value={formData.gender}
              onChange={handleChange}>
            <option value="Gender">Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
            <option value="Prefer not to respond">Prefer not to respond</option>
          </select>
        </div>
          
          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
            <input type="text" className="w-full border border-gray-300 p-2 rounded inputDouble" 
            name="spouseFirstName" value={formData.spouseFirstName} onChange={handleChange}
            required/>
            <span class="placeholder">Spouse First Name</span>
          </div>
  
          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
            <input type="text"  className="w-full border border-gray-300 p-2 rounded inputDouble" 
            name="spouseLastName" value={formData.spouseLastName} onChange={handleChange}
            required/>
            <span class="placeholder">Spouse Last Name</span>
          </div>
  
          {/* <div className="col-span-2 sm:col-span-1 mb-1">
            <select className="w-full border border-gray-300 p-2 rounded inputDouble" name="spouseAge" 
            value={formData.spouseAge} onChange={handleChange}>
              <option value="Spouse Age">Spouse Age</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>
            </select>
          </div> */}

          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
          <input type="text" className="w-full border border-gray-300 p-2 rounded inputDouble" 
             name="spouseAge"
              value={formData.spouseAge}
              onChange={handleChange} required/>
          <span class="placeholder">Spouse Age</span>
        </div>
  
          <div className="col-span-2 sm:col-span-1 mb-1">
            <select className="w-full border border-gray-300 p-2 rounded inputDouble"
            name="spouseGender" 
            value={formData.spouseGender} onChange={handleChange} placeholder="Gender">
              <option value="Spouse Gender">Spouse Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
              <option value="Prefer not to respond">Prefer not to respond</option>
            </select>
          </div>
  
          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
          <input type="tel" className="w-full border border-gray-300 p-2 rounded inputDouble"
          name="phone" value={formData.phone} onChange={handleChange} required/>
          <span class="placeholder">Phone</span>
        </div>
  
        <div className="col-span-2 sm:col-span-1 mb-1 input-container">
          <input type="email" className="w-full border border-gray-300 p-2 rounded inputDouble"
          name="email" value={formData.email} onChange={handleChange} required/>
          <span class="placeholder">Email</span>
        </div>
  
        <div className="col-span-2 mb-1">
          <input type="text" className="w-full border border-gray-300 p-2 rounded inputSingle"
          name="street" value={formData.street} onChange={handleChange} placeholder='Street Name'/>
        </div>
  
        <div className="col-span-2 sm:col-span-1 mb-1 input-container">
          <input type="text" className="w-full border border-gray-300 p-2 rounded inputDouble"
          name="city" value={formData.city} onChange={handleChange} required/>
          <span class="placeholder">City</span>
        </div>

        <div className="col-span-2 sm:col-span-1 mb-1">
          <select className="w-full border border-gray-300 p-2 rounded inputDouble" 
          name="state" value={formData.state} onChange={handleChange} placeholder="State">
          <option value="State">State</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
            <option value="District of Columbia">District of Columbia</option>
            <option value="American Samoa">American Samoa</option>
            <option value="Guam">Guam</option>
            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
            <option value="Puerto Rico">Puerto Rico</option>
            <option value="U.S. Virgin Islands">U.S. Virgin Islands</option>
            <option value="Colombia">Colombia</option>
            <option value="Jamaica">Jamaica</option>
            <option value="Nicaragua">Nicaragua</option>
            <option value="Colombia">Colombia</option>
            <option value="Honduras">Honduras</option>
            <option value="Nicaragua">Nicaragua</option>            
          </select>
        </div>
  
          <div className="col-span-2 sm:col-span-1 mb-1 input-container">
          <input type="text" className="w-full border border-gray-300 p-2 rounded inputDouble" 
          name="zip" value={formData.zip} onChange={handleChange} placeholder='Zip code' required/>
          {/* <span class="placeholder">Zip code</span> */}
        </div>
  
        <div className="col-span-2 sm:col-span-1 mb-1">
          <select className="w-full border border-gray-300 p-2 rounded inputDouble" 
          name="country" value={formData.country} onChange={handleChange} placeholder="Country">
          <option value="Country">Country</option>
            <option value="USA">USA</option>
          </select>
        </div>
    
        <div className="col-span-2 mb-1 input-container ">
          <textarea className="w-full border border-gray-300 p-2 rounded inputMessage"
          name="message" value={formData.message} onChange={handleChange} required
          rows="4"></textarea>
          <span class="placeholder textareaPlaceholder">Message</span>
        </div>
  
          <div className="col-span-2 lg:flex md:flex">
            <button type="submit" className="bg-pink-500 text-white font-semibold py-2 px-4 rounded w-full sendButton">
              Send
            </button>
            <p className='lg:ml-16 pb-20 formFooter mt-2 formFooterMarginMobile'><span style={{color:"#E61C5D"}}>*</span> Denotes required information</p>
          </div>
        </form>
      </div>
      </div>
    );
}

export default CoupleContact;
