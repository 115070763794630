import React from 'react';
import Header from './Header';
import Footer from './Footer';
import Banner from '../Containers/Banner';
import Welcome from '../Containers/Welcome';
import InfoCard from '../Containers/InfoCard';
import OfferingCard from '../Containers/OfferingCard';
import Packages from '../Containers/Packages';
import ServiceCard from '../Containers/ServiceCard';


function Home() {
  return (
    <>
    <Header/>
    <Banner/>
    <Welcome/>
     <InfoCard/>
     <ServiceCard/>
    <Packages/>
    <OfferingCard/> 
    <Footer/>
    </>
  );
}

export default Home;
