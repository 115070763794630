import React, { useState, useEffect } from 'react';
import PhotoGalleryData from '../../Data/PhotoGalleryData';

function PhotoGalleryPopUp({ isOpen, onClose, currentIndex }) {
    const [current, setCurrent] = useState(currentIndex);

    useEffect(() => {
      console.log("index:",currentIndex)
      console.log("current index:-",current)
        setCurrent(currentIndex);
    }, [currentIndex]);

    if (!isOpen) return null;

    const handlePrev = () => {
        setCurrent((prev) => (prev === 0 ? PhotoGalleryData.length - 1 : prev - 1));
    };

    const handleNext = () => {
        setCurrent((prev) => (prev === PhotoGalleryData.length - 1 ? 0 : prev + 1));
    };

    const handleThumbnailClick = (index) => {
        setCurrent(index);
    };

    return (
        <>
            {/* Main container */}
            <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-75 z-50 space-y-4 sm:space-y-8 p-4 sm:p-0">
                {/* div 1: Main Image Section */}
                <div className="relative w-full sm:w-auto">
                    <button onClick={onClose} className="absolute top-0 right-2 text-white  px-2 py-1 rounded-full sm:top-11 sm:right-12 text-4xl">
                    {/* &times; */}
                    <img src="/images/Cancel.svg" className='cancleButton cancleButtonMobile'/>
                    </button>
                    <button onClick={handlePrev} className="absolute top-1/2 left-2 transform -translate-y-1/2 text-white px-2 py-1 rounded sm:left-1">
                        <img src="/images/leftArrow.svg" alt="left Arrow images" className="ArrowImageSize" />
                    </button>
                    <img alt="gallery" className="mt-4 sm:mt-10 w-full h-full object-cover SinglePicSize SinglePicSizeMobile" src={PhotoGalleryData[current].img} />
                    <button onClick={handleNext} className="absolute top-1/2 right-2 transform -translate-y-1/2 text-white px-2 py-1 rounded sm:right-1">
                        <img src="/images/rightArrow.svg" alt="right Arrow images" className="ArrowImageSize" />
                    </button>
                </div>

                {/* Thumbnail Section */}
                <div className="relative flex justify-center items-center w-full sm:w-auto">
                    <button onClick={handlePrev} className="text-white px-2 py-1 rounded-full left-0 mb-5 sm:mt-10 flex overflow-x-auto space-x-2 max-w-full">
                        <img src="/images/leftArrow.svg" alt="left Arrow images" className="ArrowImageSizeTumbnail " />
                    </button>
                    <div className="mt-4 sm:mt-10 flex overflow-x-auto space-x-2 max-w-full">
                        {PhotoGalleryData.map((data, index) => (
                            <div
                                key={index}
                                className={`relative cursor-pointer ${
                                    index === current ? 'w-16 h-16 sm:w-24 sm:h-24' : 'w-14 h-14 sm:w-20 sm:h-20'
                                }`}
                                onClick={() => handleThumbnailClick(index)}
                            >
                                <img
                                    src={data.img}
                                    alt={`thumbnail-${index}`}
                                    className="object-cover rounded-md transition-all duration-200"
                                />
                            </div>
                        ))}
                    </div>
                    <button onClick={handleNext} className="text-white px-2 py-1 rounded-full left-0 mb-6 sm:mt-10 flex overflow-x-auto space-x-2 max-w-full">
                        <img src="/images/rightArrow.svg" alt="right Arrow images" className="ArrowImageSizeTumbnail " />
                    </button>
                </div>
            </div>
        </>
    );
}

export default PhotoGalleryPopUp;









