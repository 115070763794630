import React from 'react';

function FooterCopyrights() {
  return (
    <>
      <footer class="body-font mb-8 mt-8 footerMenuHiddenMobile">
  <div class="container mx-auto md:flex lg:flex lg:flex-wrap md:flex-wrap p-3 flex-col md:flex-row lg:items-center">
    <nav class="md:ml-auto md:mr-auto md:flex md:flex-wrap items-center text-base justify-center">
      <a class="lg:mr-5 hover:text-gray-900 FooterCopyright ">Atithi Bhv © Copyright 2024. All rights reserved.</a>
    </nav>
  </div>
</footer>

    {/* Copyright footer for mobile screen */}
    <div className='FooterCopyrightMobile lg:hidden md:hidden'>
    <a >
    Atithi Bhv © Copyright 2024. <br></br>
    All rights reserved.
    </a>
    </div>
 
    </>



  );
}

export default FooterCopyrights;



