import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ContactUsPopup from './../Component/Packages/Contact';

function FooterContact() {

  const [activeMenu1, setActiveMenu] = useState('Home');
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    const savedMenu = sessionStorage.getItem('activeMenu1');
    if (savedMenu) {
      setActiveMenu(savedMenu);
    } else {
      setActiveMenu('Home'); 
      sessionStorage.setItem('activeMenu1', 'Home'); 
    }
  }, []);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
    sessionStorage.setItem('activeMenu1', menu); 
  };

  return (
    <>
<div className="col-span-12 md:col-span-2 md:col-start-10 mb-10  lg:hidden md:hidden">
            {/* <FooterContact /> */}
            <h2 className="text-xl font-bold mt-4  mb-4">Quick Links</h2>

            <footer class="text-gray-600 body-font">
                <div class="container mx-auto  mt-5 flex-col md:flex-row items-center">

                <nav class=" md:ml-auto md:mr-auto text-base justify-center">
                <ul>
                    <li className='mt-5'>
                    <Link
                        to="/"
                        className={`mr-8 FooterMenu ${
                        activeMenu1 === 'Home' ? 'menu-active1' : ''
                    }`}
                    onClick={() => handleMenuClick('Home')}
                    >
                    Home
                    </Link>                    
                    </li>
             
                    <li className='mt-2'>
                    <Link
                        to="/TourPackages"
                        className={`mr-8 FooterMenu ${
                        activeMenu1 === 'Tour' ? 'menu-active1' : ''
                    }`}
                    onClick={() => handleMenuClick('Tour')}
                    >
                    Tour Packages
                    </Link>                    
                    </li>

                    <li className='mt-2'>
                    <Link
                        to="/PhotoGallery"
                        className={`mr-8 FooterMenu ${
                        activeMenu1 === 'Gallery' ? 'menu-active1' : ''
                    }`}
                    onClick={() => handleMenuClick('Gallery')}
                    >
                    Photo Gallery
                    </Link>                    
                    </li>

                    <li className='mt-2'>
                    <Link
                        to="/"
                        className={`mr-8 FooterMenu ${
                        activeMenu1 === 'Contact' ? 'menu-active1' : ''
                    }`}
                    onClick={() => {
                      handleMenuClick('Contact');
                      handlePopupOpen();
                    }}
                    >
                    Contact Us          
                    </Link>                    
                    </li>

               
                </ul>
                </nav>
                </div>
            </footer>
            </div>

           <div className="flex-1 FooterContact " >
        <h2 className="text-xl font-bold mb-4 lg:mt-20 footerGetinTouch">Get In Touch</h2>
        <p className="mb-2" ><img src="/images/location.svg" alt="Phone Icon" className="inline-block mr-2" />
        <span className="FooterContactText FooterContactTextMobile">Atithi Bhv </span><br/>
        <span className="FooterContactText ml-6 FooterContactTextMobile">Iselin, NJ 08830</span>
     
       </p>
        <p className="mb-4">
          <img src="/images/call.svg" alt="Phone Icon" className="inline-block mr-2" />
          <span className="FooterContactText FooterContactTextMobile">732-347-8628</span>
        </p>
        <p>
        <img src="/images/mail.svg" alt="Phone Icon" className="inline-block mr-2" />
        <span className="FooterContactText FooterContactTextMobile">info@atithibhv.com</span>
        </p>
      </div>  

      

         {/* footer messages icon for mobile screen*/}

      <div className="flex space-x-4 lg:hidden mt-7">
      <a href="#" className="text-blue-600">
      <img src="/images/Facebook.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a>
      {/* <a href="#" className="text-blue-700">
      <img src="/images/Linked.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a>
      <a href="#" className="text-blue-700">
      <img src="/images/Twitter.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a> */}
      <a href="#" className="text-blue-600">
      <img src="/images/Instagram.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a>
    </div>

    {/* <hr className='mt-9 lg:hidden md:hidden' ></hr> */}

    <ContactUsPopup isOpen={isPopupOpen} onClose={handlePopupClose} />
    </>

  );
}

export default FooterContact;
