import React from 'react';

function Welcome() {
  return (
    <>
    <div>
    <section class="text-gray-600 body-font overflow-hidden">
  <div class="md:py-24  mx-auto">
    <div class="lg:w-5/5 mx-auto flex flex-wrap md:px-20">
      <img alt="ecommerce" class="lg:w-1/2 w-full lg:h-auto h-64 object-cover object-center rounded welcomeImage welcomeImageTablate" src="/images/welcome.svg"/>
      <div class="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
        <h2 class="text-sm title-font text-gray-500 tracking-widest welcomeFont uppercase lg:mx-10 welcomeHeaderMobile">WELCOME</h2>
        <p class="text-gray-900 text-3xl title-font font-medium mb-1 welcomeHeader lg:ml-10 welcomeHeaderContentMobile">Our senior care packages <span className='welcomeHeaderPink welcomeHeaderContentPinkMobile'>go beyond</span> mere <span className='welcomeHeaderYellow welcomHeaderYellowMobile'>vacation planning.</span></p>   
        <p class="leading-relaxed welcomeText  lg:mx-10 welcomeTextMobile">
        We'll assist with medical needs, revitalize with traditional therapies, and rekindle spirits with pilgrimages to India's sacred sites. Share your vision of an enriching journey, and we'll make it a reality.
        </p>
      </div>
    </div>
    </div>
    </section>
    </div>
    </>
  );  
}



export default Welcome;



//--------------------------------------------------------------------------------
{/* <p class="text-gray-900 text-3xl title-font font-medium mb-1 welcomeHeader lg:ml-10 welcomeHeaderContentMobile">Our senior care packages <span className='welcomeHeaderPink'>go beyond</span>mere <span className='welcomeHeaderYellow'>vacation planning. </span></p>    */}

