import React from 'react';

function ServiceCard() {

    return (
    <section class="text-gray-600 body-font md:mt-15 ">
    <div class="container px-12 mx-auto">
    <div class="flex flex-wrap lg:-m-4">
    <div className="grid grid-cols-1 md:grid-cols-12 lg:gap-8 md:gap-8 md:p-4 lg:p-4">
                        {/* first div */}
                        <div className="col-span-12 md:col-span-12 lg:col-span-6 bg-blue-100 serviceCard serviceCardFirstBg service1 relative ">
                            <h2 className="ml-10 mt-10 mb-5 uppercase serviceCardHeader serviceCardHeaderMobile">Our Services</h2>
                            <h2 className="text-xl font-bold mb-8 serviceCardFirstContentFirst serviceCardFirstContentFirstMobile ml-10">
                                Rejuvenate, Rediscover, Relive: Senior Package with Unparalleled Care
                            </h2>
                            <div className="md:flex md:justify-between">
                                <div className="md:w-1/2">
                                    <p className="mx-10 serviceCardFirstContent serviceCardFirstContentMobile">
                                            Our customized senior care package is meticulously crafted to offer a haven of comfort and confidence, ensuring a heartwarming, enriching experience. Imagine your loved one:
                                        </p>
                                    </div>
                                    <div className="mt-4 md:mt-0 md:ml-4 md:w-1/2 md:flex md:items-end md:justify-end">
                                        <img src="/images/serviceImg1.svg" alt="Service1" className="serviceCardImg1" />
                                    </div>
                                </div>
                        </div>

                        {/* second div */}
                        <div className="col-span-12 md:col-span-3 lg:col-span-3  md:col-span-6 md:col-start-1 lg:col-start-7 bg-white serviceCard serviceCardSecondBg relative serviceMarginMobile">
                            <img src="/images/serviceImg2.svg" alt="Service2" className="absolute" />
                            <div className="serviceImgMargin">
                                <h2 className="text-xl font-bold mb-4 serviceCardSecondHeader serviceCardSecondHeaderMobile mx-10">
                                    Embark on fulfilling pilgrimages:
                                </h2>
                                <p className="serviceCardSecondContent serviceCardSecondContentMobile mx-10">
                                    Our customized senior care package is meticulously crafted to offer a haven of comfort and confidence, ensuring a heartwarming, enriching experience. Imagine your loved one:
                                </p>
                            </div>
                        </div>

                        {/* third div */}
                        <div className="col-span-12 lg:ml-2 lg:mr-7 lg:col-span-3 md:col-span-6 md:col-start-7 bg-green-100 serviceCard serviceCardThirdBg relative">
                            <h2 className="text-xl font-bold mb-4 serviceCardThirdHeader serviceCardThirdHeaderMobile mx-10 mt-10">
                                Supported by a 24/7 dedicated caretaker
                            </h2>
                            <p className="serviceCardThirdContent serviceCardThirdContentMobile mx-10">
                                Your loved one will receive round-the-clock assistance, promoting recovery and well-being.
                            </p>
                            <img src="/images/serviceImg3.svg" alt="Service3" className="serviceCardImg2 mt-10 md:mt-0 md:float-right md:flex md:items-end" />
                        </div>
    </div>
  </div>
  </div>
  </section>
  );

}

export default ServiceCard;







