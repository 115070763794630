import React, { useState } from 'react';
import Header from '../../Component/Header'
import Footer from '../Footer';
import ContactPopup from './CoupleContact'

function CouplePackage() {

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  return (
    <>
    <Header/>
    {/* couple page banner  */}
    <div className="relative">
      <img src={'/images/couple_package.svg'} alt="couple Package" className="w-full object-cover" />
      <div className="absolute bottom-0 right-0 ">
        <div className="text-white text-right">
          <h1 className="text-xl lg:text-2xl font-bold lg:mb-12 couplePackageText couplePackageTextMobile couplePackageTextTablate">Couple package</h1>
        </div>
      </div>
    </div>

    {/* couple page offering section */}
    <section className="text-gray-600 body-font overflow-hidden lg:mt-20 mt-10 md:mt-20">
      <div className="container md:y-24 mx-auto lg:ml-20 md:ml-10 sm:ml-5">
      <h2 className="text-sm title-font text-gray-500 tracking-widest welcomeFont uppercase offeringMargin offeringFontMobile">Offerings</h2>
      <h1 className="text-3xl title-font font-medium mb-4 offeringHeader offeringMargin CouplePackageOfferingHeaderMobile">
            Couple Package includes      
      </h1>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
          <div className="col-span-1 lg:col-span-6 lg:pr-10 lg:py-1  lg:mb-0 ">
            <div className="col-span-1 lg:col-span-6 lg:hidden md:hidden offeringImageSize">
            <img
              alt="ecommerce"
              className="w-full lg:h-auto h-64 object-cover object-center rounded coupleImgMobile"
              src="/images/couple_pack_img2.svg"
            />
          </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringGreenSinglePer text-black flex-shrink-0">
                <img src={'/images/single_per_svg1.svg'}  alt="Single Person Package" className="w-full PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile">
              One month stay at 2 bedroom full furnished apartment with basic amenities</h2>
            </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringYellowSinglePer text-black flex-shrink-0">
              <img src={'/images/single_per_svg2.svg'}  alt="Single Person Package" className="w-full PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile ">
              12 hours at home care giver </h2>
            </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringBlueSinglePer text-black flex-shrink-0">
              <img src={'/images/single_per_svg3.svg'}  alt="Single Person Package" className="w-full PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile ">
              Home cook service for two meals              
              </h2>
            </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringPinkSinglePer text-black flex-shrink-0">
              <img src={'/images/single_per_svg4.svg'}  alt="Single Person Package" className="w-full PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile ">
              Daily home cleaning maid service              
              </h2>
            </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringBlueSinglePer text-black flex-shrink-0">
              <img src={'/images/single_per_svg5.svg'}  alt="Single Person Package" className="w-full PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile ">
              Physiotherapy sessions at home              </h2>
            </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringPinkSinglePer text-black flex-shrink-0">
              <img src={'/images/single_per_svg6.svg'}  alt="Single Person Package" className="w-full PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile">
                Four days day-trip               
              </h2>
            </div>

            <div className="flex items-center mb-8 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringGreenSinglePer text-black flex-shrink-0">
                <img src={'/images/couple_pack_svg7.svg'}  alt="Single Person Package" className="w-fullct- PackagesSvg" />
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium  offeringTextSinglePer offeringTextMobile">
                Free Dental checkup              
              </h2>
            </div>

            <button className="bg-pink-500 navbar-button hover:bg-pink-600 text-white font-semibold py-2 px-12 ml-5 contactUsSinglePer contactUsSinglePerMobile"
            onClick={handlePopupOpen}>
            Contact Us
            </button>

          </div>
          <div className="col-span-1 lg:col-span-6 offeringImageMobile">
            <img
              alt="ecommerce"
              className="w-full lg:h-auto h-64 object-cover object-center rounded coupleImageTablate"
              src="/images/couple_pack_img2.svg"
            />
          </div>
        </div>
      </div>
    </section>

    <ContactPopup isOpen={isPopupOpen} onClose={handlePopupClose} />
    <Footer/>
    </>
  );
}

export default CouplePackage;
