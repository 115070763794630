import React from 'react';
import InfoData from "./../Data/InfoCardData"

function InfoCard() {
  return (
    <>
    <section class="text-gray-600 body-font">
    <div class="container px-12 mx-auto">
    <div class="flex flex-wrap lg:-m-4 ">
    {
      InfoData && InfoData.map((data)=>{
        return (
          <div class="md:p-4 lg:w-1/4 md:w-1/2 mb-5">
        <div class="h-full flex flex-col items-center text-left infocardbg infocardMargin">
          <img alt="team" class="flex-shrink-0 rounded-lg w-full h-100 object-cover object-center mb-4" src={data.img}/>
          <div class="w-full mb-12">
            <h2 class="title-font font-medium text-lg text-gray-900 infoCardMargin infoCardHeader InfocardTitleMobile">{data.headerData}</h2>
            <p class="mb-4 infoCardMargin infoCardText">{data.mainText}
            </p>
          </div>
        </div>
      </div>
        )
      })
    }
    </div>
  </div>
    </section>
    </>
  );
}

export default InfoCard;

