import React from 'react';

function FooterInformation() {
  return (
    <div className="flex-1">
    <h1 className="text-3xl FooterHead mt-6 font-bold mb-4 FooterHeadMobile">Say Hello!</h1>
    <p className="text-xl FooterSecondHead font-semibold text-pink-600 mb-2 FooterSecondHeadMobile">ATITHI BHV</p>
    <p className="text-gray-600 mb-4 FooterInfo FooterInfoMobile">
    Our senior care packages go beyond mere vacation planning. 
    We'll assist with medical needs, 
    revitalize with traditional therapies, 
    and rekindle spirits with pilgrimages to India's sacred sites. 
    Share your vision of an enriching journey, 
    and we'll make it a reality.   
    </p>
    <div className="flex space-x-4 footerIconMobile">
      <a href="#" className="text-blue-600">
      <img src="/images/Facebook.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a>
      {/* <a href="#" className="text-blue-700">
      <img src="/images/Linked.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a>
      <a href="#" className="text-blue-700">
      <img src="/images/Twitter.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a> */}
      <a href="#" className="text-blue-600">
      <img src="/images/Instagram.svg" className="w-100 h-10 mr-2" alt="Logo" />
      </a>
    </div>
  </div>
  );
}

export default FooterInformation;
