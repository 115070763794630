import React from 'react';

function OfferingCard() {
  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container md:y-24 mx-auto lg:ml-20 md:ml-10 sm:ml-5">
      <h2 className="text-sm title-font text-gray-500 tracking-widest welcomeFont uppercase offeringMargin offeringFontMobile">Offerings</h2>
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
          <div className="col-span-1 lg:col-span-6 lg:pr-10 lg:py-1  lg:mb-0 ">
            <h1 className="text-3xl title-font font-medium mb-4 offeringHeader offeringMargin offeringHeaderMobile">
              Atithi Bhv offers<br/>the following:
            </h1>

            <div className="col-span-1 lg:col-span-6 lg:hidden md:hidden offeringImageSize">
            <img
              alt="ecommerce"
              className="w-full lg:h-auto h-64 object-cover object-center rounded coupleImgMobile"
              src="/images/Offering.svg"
            />
          </div>

            <div className="flex items-center mb-6 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringGreen text-black flex-shrink-0">
                <span className="countColor">1</span>
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium welcomeHeaderYellow offeringText offeringTextMobile welcomeHeaderYellowMobile ">
                Luxuries Home Stay Ahmedabad for Seniors
              </h2>
            </div>

            <div className="flex items-center mb-6 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringYellow text-black flex-shrink-0">
                <span className="countColor">2</span>
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium welcomeHeaderYellow offeringText offeringTextMobile welcomeHeaderYellowMobile">
                24 x 7 Caretaker
              </h2>
            </div>

            <div className="flex items-center mb-6 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringGray text-black flex-shrink-0">
                <span className="countColor">3</span>
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium welcomeHeaderYellow offeringText offeringTextMobile welcomeHeaderYellowMobile">
                Pilgrimage in Gujarat
              </h2>
            </div>

            <div className="flex items-center mb-3 offeringMargin offeringMarginMobile">
              <div className="w-12 h-12 mr-5 inline-flex items-center justify-center rounded-full offeringPink text-black flex-shrink-0">
                <span className="countColor">4</span>
              </div>
              <h2 className="text-gray-900 text-lg title-font font-medium welcomeHeaderYellow offeringText offeringTextMobile welcomeHeaderYellowMobile ">
                Ayurvedic Treatment Assistance
              </h2>
            </div>
          </div>

          <div className="col-span-1 lg:col-span-6 offeringImageMobile">
            <img
              alt="ecommerce"
              className="w-full lg:h-auto h-64 object-cover object-center rounded offeringImageTablate"
              src="/images/Offering.svg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default OfferingCard;

