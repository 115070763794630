let Data=
    [
        {
            img:"/images/gallery1.svg",
        },
        {
            img:"/images/gallery2.svg",   
        },
        {
            img:"/images/gallery3.svg",
        },
        {
            img:"/images/gallery4.svg",
        },
        {
            img:"/images/gallery5.svg",
        },
        {
            img:"/images/gallery6.svg",
        },
        {
            img:"/images/gallery7.svg",
        }
       
    ]
    
    export default Data;